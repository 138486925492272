import { Body1, Card, CardHeader, CardPreview, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import * as React from 'react'

const useStyles = makeStyles({
    headerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    disclaimerText: {
        paddingLeft: "10px"
    },
    container: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexWrap: "wrap"
    },
    card: {
        width: "90%",
        maxWidth: "480px",        
        height: "fit-content",
        flexBasis: "auto",
        boxSizing: "border-box",
        backgroundColor: tokens.colorBrandBackground
    },
    cardPreview: {
        backgroundColor: tokens.colorNeutralBackground1,
        minHeight: "145px",
        ...shorthands.padding("15px")
    },
    imageContainer: {
        height: "200px !important"
    },
    descriptionContainer: {
        paddingTop: "15px"
    }
});

const FAQ: React.FC = () => {
    const styles = useStyles();

    return (
        <div>
            <div className={styles.headerContainer}><h1>FAQ</h1></div>            
            <div className={styles.container}>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Where does the money raised go?</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>                        
                        <div className={styles.descriptionContainer}>The Halloween Carnival is not being run as a fundraiser. Instead the PTO provided start up funds for this year and money from ticket sales will be used to fund next year's Halloween Carnival.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Can I bring my younger/older children?</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>                        
                        <div className={styles.descriptionContainer}>Yes. While the games and the event are geared towards K-4 children all ages are welcome to come and participate. For younger children we can't guarentee that the candy or prizes will be completely safe/appropriate but they are still welcome to come and experience the event.</div>
                    </CardPreview>
                </Card>
            </div>
        </div>
    );
};

export default FAQ;