import { Body1, Button, /* Button, */ Card, CardHeader, CardPreview, /* Tooltip, */ makeStyles, shorthands, tokens, Tooltip } from '@fluentui/react-components';
import { PeopleTeamFilled } from '@fluentui/react-icons';
import * as React from 'react'

const useStyles = makeStyles({
    container: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexWrap: "wrap"
    },
    card: {
        width: "90%",
        maxWidth: "480px",
        height: "fit-content",
        backgroundColor: tokens.colorBrandBackground
    },
    cardPreview: {
        backgroundColor: tokens.colorNeutralBackground1,
        minHeight: "72px",
        ...shorthands.padding("15px")
    },
    meetingButton: {
        marginLeft: "10px"
    },
    resourcesDiv: {
        marginTop: "10px"
    }
});

const Meetings: React.FC = () => {
    const styles = useStyles();

    const openUrl = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <div>
            <h1>Upcoming Meetings</h1>
            <div className={styles.container}>
                
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Meeting 1</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div>
                            <strong>Time:</strong> 9/24/2024: 6:00pm-6:30pm
                        </div>
                        <div>
                            <strong>Location:</strong> Online (Microsoft Teams Meeting)
                        </div>
{/*                           <div>
                            <strong>Online:</strong>
                            <Tooltip content="View Microsoft Teams meeting" relationship='description'>
                                <Button className={styles.meetingButton} icon={<PeopleTeamFilled />} onClick={() => openUrl('https://teams.live.com/meet/9386150605388?p=9S5ecjx1oMzLZjjnX4')} />                          
                            </Tooltip>
                            
                        </div>
                      <div className={styles.resourcesDiv}>
                            <strong>Resources:</strong>
                            <Tooltip content="View Agenda" relationship='description'>
                                <Button className={styles.meetingButton} icon={<CalendarAgendaFilled />} onClick={() => openUrl('https://1drv.ms/w/s!Ag5MeLdyRyw358NGU4GFYdF6wtJGNQ?e=bIWCcO')} />
                            </Tooltip>
                            <Tooltip content="View Minutes" relationship='description'>
                                <Button className={styles.meetingButton} icon={<BookClockFilled />} onClick={() => openUrl('https://1drv.ms/w/s!Ag5MeLdyRyw358NOUxiDMIyAcnLlCA?e=Xb7y1z')} />
                            </Tooltip>
                        </div> */}
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Meeting 2</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div>
                            <strong>Time:</strong> 10/8/2024: Following the PTO Meeting (~7:00pm)
                        </div>
                        <div>
                            <strong>Location:</strong> Online (Microsoft Teams), Elementary Teacher's Lounge
                        </div>
                        <div>
                            <strong>Online:</strong>
                            <Tooltip content="View Microsoft Teams meeting" relationship='description'>
                                <Button className={styles.meetingButton} icon={<PeopleTeamFilled />} onClick={() => openUrl('https://teams.live.com/meet/9329909016887?p=dm1k7oy2UzcSiBmbhs&eventType=community')} />                          
                            </Tooltip>                            
                        </div> 
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Meeting 3</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div>
                            <strong>Time:</strong> TBD
                        </div>
                        <div>
                            <strong>Location:</strong> TBD
                        </div>
                    </CardPreview>

                </Card>
            </div>
        </div>
    );
};

export default Meetings;